<template>
<div class="main">
    <div class="side-nav">
        <div class="topInfo">
            {{company}}
        </div>
        <ul>
            <li>
                <router-link to="/shopManage/walfproduct">商品列表</router-link>
            </li>
            <li>
                <router-link to="/shopManage/walflist">订单列表</router-link>
            </li>
            <li>
                <router-link to="/shopManage/cityServe">同城信息</router-link>
            </li>
        </ul>
        <div class="out" @click="outLog">登出</div>
    </div>
    <div class="side-cont">
        <router-view></router-view>
    </div>
</div>
</template>

<script>
    let app = null

export default {
    data() {
        return {
            company:""
        }
    },
    created() {
        app = this.$.appContext.app;
        let info = JSON.parse(sessionStorage.getItem("member")) 
        console.log("info",info)
        this.company = info.Merchant_Name
    },
    methods: {
        outLog(){
            sessionStorage.setItem("loginState", 0)
            sessionStorage.removeItem("member")
            this.clearAllCookie()
            this.$message("登出成功")
            this.$router.push({
                path: "/login"
            })
        }
    }
}
</script>

<style scope>
.out{
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 200px;
    display: block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
.out:hover{
    color: rgba(255,255,255,0.6)
}
.dialog-footer{
    margin-top: 20px;
    text-align: center
}
.topInfo{
    line-height: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    border-bottom:1px solid #fff;
    
}
.main {
    display: flex;
    height: calc(100vh - 1px);
}
.side-nav{
    position: relative;
    padding-top: 30px;
    width: 200px;
    height: calc(100% - 30px);
    background-color: #496cfc;
}
.side-nav ul,.side-nav li{
    list-style: none;
}
.side-nav li a{
    font-size: 16px;
    color: #fff;
    padding: 12px;
    display: inline-block

}
.side-nav li a:hover{
    color: rgba(255,255,255,0.7)
}
.side-cont{
    width: calc(100% - 200px);
    overflow: hidden;
    overflow-y: auto;
}
.el-pagination {
    margin: 20px 0;
    text-align: center
}
</style>
